import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { FormattedMessage } from "react-intl"
import Link from "../components/link"
import Layout from "../layouts/en"
import CompanyPageBase from "../templates/company"
import Intro from "../components/intro"
import Figure from "../components/figure"
import History from "../components/history"
import TextSection from "../components/text-section"
import Box from "../components/box"
import Download from "../components/download"
import EnvironmentStats from "../components/stats-environment"
import Teaser from "../components/teaser"

export default () => {
  const { fileNodeIatf, fileNodeIso, heroImage, policyImage } = useStaticQuery(
    graphql`
      query {
        heroImage: file(relativePath: { glob: "company-hero/*.jpg" }) {
          childImageSharp {
            gatsbyImageData(quality: 95, layout: FULL_WIDTH)
          }
        }
        policyImage: file(relativePath: { eq: "mitarbeiter-an-maschine.jpg" }) {
          childImageSharp {
            gatsbyImageData(quality: 95, layout: FULL_WIDTH)
          }
        }
        fileNodeIatf: sanityDownload(
          file: {en: {fileName: {eq: "finkernagel_certificate_iatf-16949-2016.en-0469849383747a5dd7f568810376a8c2"}}}
        ) {
          id
          title {
            en
          }
          file {
            en {
              fileName
              file {
                asset {
                  extension
                  size
                }
              }
            }
          }
        }
        fileNodeIso: sanityDownload(
          file: {en: {fileName: {eq: "finkernagel_certificate_iso_9001-2015.en-aa6278e16d8c02c52c74f8d3d03eff96"}}}
        ) {
          id
          title {
            en
          }
          file {
            en {
              fileName
              file {
                asset {
                  extension
                  size
                }
              }
            }
          }
        }
      }
    `
  )

  return (
    <Layout>
      <CompanyPageBase locale="en">
        <Intro title="Wire and forming tools from one source">
          <p>
            Finkernagel supplies cold heading wire and forming tools of the
            highest standard to the automotive, building, electrical and
            mechanical engineering industries. Qualified employees,
            state-of-the-art machinery and plants, surface eddy current testing
            and comprehensive individual coil-tracking make Finkernagel one of
            the leading suppliers in its market segment.
          </p>
        </Intro>
        <Figure
          image={heroImage.childImageSharp.gatsbyImageData}
          alt="Production facilities"
          caption="Bringing together the best of tradition and cutting-edge technology: Our production facilities are state-of-the-art."
        />
        <History />
        <TextSection title="We are Finkernagel">
          <div className="mx-auto mb-sm lg:ml-0">
            <div className="relative h-0 pt-3/2 overflow-hidden">
              <div className="absolute left-0 top-0 h-full w-full">
                <iframe
                  title="YouTube Video"
                  className="w-full h-full"
                  width="560"
                  height="315"
                  src="https://www.youtube-nocookie.com/embed/8HI2fTHKsFQ?rel=0"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </TextSection>
        <TextSection title="Quality">
          <p>
            Motivated employees who receive continuous training implement the
            demanding requirements and preferences of our customers. We stand
            for high quality products, prompt delivery and expert service.
          </p>

          <Box title="We are certified in accordance with EN ISO 9001 and IATF 16949">
          <Download extension={fileNodeIatf.file.en.file.asset.extension}
              fileName={`/static/${fileNodeIatf.file.en.fileName}.${fileNodeIatf.file.en.file.asset.extension}`} 
              key={fileNodeIatf.id} 
              title={fileNodeIatf.title.en} 
              size={fileNodeIatf.file.en.file.asset.size}/>
            <Download extension={fileNodeIso.file.en.file.asset.extension}
              fileName={`/static/${fileNodeIso.file.en.fileName}.${fileNodeIso.file.en.file.asset.extension}`} 
              key={fileNodeIso.id} 
              title={fileNodeIso.title.en} 
              size={fileNodeIso.file.en.file.asset.size}/>
          </Box>
        </TextSection>
        <TextSection title="Sustainability">
          <p>
            In line with our environmental policy, amongst other things we have
            installed <strong>1,850 solar modules</strong> on the roofs of our
            production halls. This equates to a rated output of{" "}
            <strong>515 kWh</strong>. We are thus able to save up to{" "}
            <strong>
              300 tonnes of CO<sub>2</sub>
            </strong>
             annually.
          </p>
          <EnvironmentStats />
        </TextSection>
        <Teaser image={policyImage}>
          <h1>Our company philosophy</h1>
          <p>
            We are a successful independent family business with an
            internationally recognised position in the market. Our employees
            actively participate in the implementation of our company
            philosophy.
          </p>
          <Link to="/policy" className="accessory">
            <FormattedMessage id="global.explore" />
          </Link>
        </Teaser>
      </CompanyPageBase>
    </Layout>
  )
}
